import { useEffect, useState } from "react"

const initialState = [
  {
    currency: "EUR",
    basicAmount: 9,
    proAmount: 20,
    boostAmount: 10,
    quotproAmount: 9,
    viewportAmount: 20,
    proplusAmount: 65,
  },
  {
    currency: "USD",
    basicAmount: 10,
    proAmount: 22,
    boostAmount: 12,
    quotproAmount: 10,
    viewportAmount: 22,
    proplusAmount: 75,
  },

  {
    currency: "GBP",
    basicAmount: 7,
    proAmount: 17,
    boostAmount: 8,
    quotproAmount: 7,
    viewportAmount: 17,
    proplusAmount: 60,
  },
  {
    currency: "INR",
    basicAmount: 700,
    proAmount: 1500,
    boostAmount: 700,
    quotproAmount: 700,
    viewportAmount: 1500,
    proplusAmount: 5000,
  },
  {
    currency: "AUD",
    basicAmount: 15,
    proAmount: 33,
    boostAmount: 17,
    quotproAmount: 15,
    viewportAmount: 33,
    proplusAmount: 110,
  },
  {
    currency: "CAD",
    basicAmount: 14,
    proAmount: 31,
    boostAmount: 15,
    quotproAmount: 14,
    viewportAmount: 31,
    proplusAmount: 65,
  },
  {
    currency: "SGD",
    basicAmount: 15,
    proAmount: 32,
    boostAmount: 16,
    quotproAmount: 15,
    viewportAmount: 32,
    proplusAmount: 110,
  },
]
export default function UsePrice() {
  const [price, setPrice] = useState(initialState[0])
  const [ip, setIp] = useState(null)
  useEffect(() => {
    // fetch("https://ip.seeip.org")
    //   .then((res) => res.text())
    //   .then((data) => setIp(ip))
    // .catch((err) => console.log(err))
  }, [])
  console.log(ip)
  useEffect(() => {
    fetch(`https://ipapi.co/${ip}/json`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data.country_code === "IN") {
          setPrice(initialState[3])
        } else if (data.country_code === "GB") {
          setPrice(initialState[2])
        } else if (data.country_code === "EU") {
          setPrice(initialState[0])
        } else if (data.country_code === "AU") {
          setPrice(initialState[4])
        } else if (data.country_code === "CA") {
          setPrice(initialState[5])
        } else if (data.country_code === "SG") {
          setPrice(initialState[6])
        } else {
          setPrice(initialState[1])
        }
      })
      .catch((err) => console.log(err))
  }, [ip])

  // useEffect(() => {
  //   fetch(
  //     "https://cors-anywhere.www.tekorero.com/https://api.ipify.org/?format=json"
  //   )
  //     .then((res) => res.json())
  //     .then((data) => setIp(data.ip))
  // }, [])

  console.log(price)
  return price
}

import React, { useState, useMemo } from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap"
import feature_mobile from "../../images/features_mobile.jpg"
import {
  Integrations,
  Integrations1,
  Box5,
  Content,
  Box6,
} from "../../components/UI"
// import ScrollToTop from "../ScrollToTop"
import { loginUrl } from "../../config/keys"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import CARDIMG1 from "../../images/smartboost.jpg"
import { LandingScreen } from "../Home/Styled"
import hubImage from "../../images/Features1.jpg"
import UsePrice from "../../Hooks/UsePrice"
import "./Integrations.css"
import Signup from "../Home/signup"
import ScrollToTop from "./ScrollToTop"
import ViewPort_hires from "../../images/ViewPort_hires.jpg"

function Homepage() {
  const { currency, boostAmount, quotproAmount, viewportAmount } = UsePrice()
  const [valueAddNav, setValueAddNav] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const items = [
    { title: "How can I support better with my customer?" },
    { title: "Can I have information about my customer to refine my pitch?" },
    { title: "How do I know which products are best sellers?" },
    {
      title:
        "How can I have real time information on sales of my invested company?",
    },
  ]

  function next() {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  function previous() {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  function goToIndex(newIndex) {
    setActiveIndex(newIndex)
  }

  // const scrollTop = useMemo(() => {
  //   return window.scrollY
  // }, [window.scrollY]);

  // const fixedStyles = {
  //   position: 'fixed',
  //   top: 0,
  //   left: 0,
  //   zIndex: 9999

  // }
  const slides = items.map((item) => (
    <CarouselItem>
      <div className="top-left22">
        <h1>{item.title}</h1>
      </div>
    </CarouselItem>
  ))
  return (
    <div>
      <div className="menu">
        <div className="megamenushow">
          <div
            className="mega-menu"
            style={{
              marginTop: "40px",
              marginLeft: "100px",
              marginRight: "100px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                // justifyContent: "space-evenly",
                padding: "20px",
              }}
            >
              <div
                style={{
                  width: "30%",

                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "100%",

                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "19%", backgroundColor: "#2dc8ff" }}>
                    <i
                      class="fa fa-handshake-o"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        marginLeft: "8px",
                        fontSize: "36px",
                        marginTop: "10px",
                      }}
                    ></i>
                  </div>
                  &nbsp; &nbsp;
                  <div style={{ width: "75%" }}>
                    <span className="feature-dropdown-header ">
                      <a href="#3" style={{ color: "white" }}>
                        {" "}
                        ViewPort
                      </a>
                    </span>

                    <div className="feature-dropdown-content">
                      Track performance of invested firms, subsidiaries in real
                      time
                    </div>
                  </div>
                </div>
                &nbsp; &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile_text">
        <div style={{ textAlign: "justify" }}>
          <span style={{ color: "#29c8ff" }}>Increase REVENUE, </span>Improve
          Productivity{" "}
          <span style={{ color: "#29c8ff" }}>& Grow your CUSTOMER BASE</span>
          <br />
        </div>
      </div>
      <LandingScreen
        backgroundImage={`url(${hubImage})`}
        mobileBackgroundImage={`url(${feature_mobile})`}
        // height="600px"
      >
        {/* <div className="landing_image_main_box">
          <div className="landing_image_main_box_first_child">
            <div className="image_text_For_landing_page">
              <span style={{ color: "white" }}>
                &nbsp;&nbsp;&nbsp;&nbsp; {" "}
              </span>
              Improve Productivity
              <br />
              <span style={{ color: "white" }}>& Grow your CUSTOMER BASE</span>
            </div> */}
        <div className="landing_image_main_box">
          <div className="landing_image_main_box_first_child">
            <div className="image_text_For_landing_page">
              <br />
              <br />

              <p style={{ color: "black", margin: "0px 40px" }}>
                <span style={{ color: "white" }}>Increase REVENUE,</span>{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  Improve Productivity
                  <br />
                </span>
                <span style={{ color: "white" }}>
                  & Grow your CUSTOMER BASE
                </span>
              </p>
            </div>

            <div className="button_for_landing_page_in_web">
              <a
                onClick={(e) => {
                  trackCustomEvent({
                    category: "start your free trial caraousel button ",

                    action: "Click",

                    label: "Start your free trial button in home page",

                    value: 43,
                  })
                }}
                className="banner_buttons"
                href={`${loginUrl}/register/Option`}
                target="_blank"
              >
                START YOUR 30 DAYS FREE TRIAL NOW!
              </a>
            </div>
          </div>
        </div>
      </LandingScreen>
      {/* <div class="space"></div> */}
      <div className="landing-button_forMobile_feature">
        <a
          onClick={(e) => {
            trackCustomEvent({
              category: "start your free trial caraousel button ",

              action: "Click",

              label: "Start your free trial button in home page",

              value: 43,
            })
          }}
          className="banner_buttons"
          href={`${loginUrl}/register/Option`}
          target="_blank"
        >
          START YOUR 30 DAYS FREE TRIAL NOW!
        </a>
      </div>

      <div className="page_body1">
        <div className="feature1" id="1">
          <h1>ViewPort</h1>
        </div>
        <div className="spacer"></div>
        <div className="feature-left-box-content1">
          Interested in having a real time view of sales performance of
          subsidiary / invested portfolio? <br /> <br />
          ViewPort provides a secure channel for data transfer between two
          parties.
          <br /> <br />
          Similarly data of subsidiaries or sister companies can be easily
          configured and accessed in real time through ViewPort.
          <br /> <br />
        </div>
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={ViewPort_hires}
                alt="ViewPort_hires"
                className="border-image"
              />
            </div>
          </div>

          <div className="feature-left-box ">
            <p className="feature-left-box-content">
              ViewPort is an unique service in Korero where two non related
              parties agree to share data in a secured manner. ViewPort is not
              part of the core CRM offering, it needs to be enabled by the
              parties, admin can enable Viewport in the control tower.
              <br /> <br />
              Users will be able to access rich dashboard and reports with YoY,
              QoQ and MoM performance, funnel size, sales velocity and more.
              Users will access enterprise wide data of investee or subsidiary
              published in the reporting currency possible through Live currency
              conversion.
            </p>
          </div>
        </div>

        <div className="value_add_price">US$22</div>
        <div className="valueadd-pricingcontent">
          <p>per user/month (ex. VAT)</p>
        </div>
        <div
          className="feature-main-box "
          data-sal="slide-down"
          data-sal-delay="50"
          data-sal-easing="ease-in-out"
          data-sal-duration="300"
        >
          <div className="feature-left-box ">
            <div className="feature-image-hide-on-web">
              <Box5>
                <div className="smartboostimg">
                  <img
                    src={CARDIMG1}
                    height="300"
                    width="470"
                    marginTop="112px"
                  />
                </div>
                <div className="valueadd-price">
                  {/* <h1
                style={{
                  fontWeight: 400,
                  fontSize: "50px",
                  marginTop: " 40px",
                  color: "#215ca0",
                }}
              >
                {currency === "USD" && <span>US&#36;</span>}
                {currency === "EUR" && <span>&euro;</span>}
                {currency === "GBP" && <span>&#163;</span>}
                {currency === "INR" && <span>&#x20B9; </span>}
                {currency === "AUD" && <span>AU&#36;</span>}
                {currency === "CAD" && <span>CA&#36;</span>}
                {currency === "SGD" && <span>SG&#36; </span>}
                {boostAmount}{" "}
                {currency === "USD" && <span>&#36;</span>}
                {currency === "EUR" && <span>&euro;</span>}
                {currency === "GBP" && <span>&#163;</span>}
                {currency === "INR" && <span>&#x20B9;</span>}
                {boostAmount}
                <br />
              </h1> */}
                </div>
                <div className="valueadd-pricingcontent">
                  <p>per user/month (ex. VAT)</p>
                </div>
              </Box5>
            </div>
          </div>
        </div>
        <div className="feature-main-box_mobile ">
          <div className="feature-right-box ">
            <div className="feature-image">
              <img
                src={ViewPort_hires}
                alt="ViewPort_hires"
                className="border-image"
              />
            </div>
          </div>
          <div className="feature-left-box ">
            {/* <div className="feature-left-box-heading">
              Configurable Fiscal period
            </div> */}
            <div className="spacer"></div>
            <div className="feature-image-hide-on-web">
              <div className="feature-image_onMobile">
                <img
                  src={ViewPort_hires}
                  alt="dasboard"
                  className="border-image"
                />
              </div>
            </div>
            <div className="value_add_price_mobile">US$22</div>
            <div className="valueadd-pricingcontent_mobile">
              <p>per user/month (ex. VAT)</p>
            </div>
            <p className="feature-left-box-content">
              ViewPort is an unique service in Korero which two non related
              parties agree to share data in a secured manner. ViewPort is not
              part of the core CRM offering, it needs to be enabled by the
              parties, admin can enable Viewport in the control tower.
              <br />
              <br />
              Viewport usage is charged separately and billed per user per
              month. Users will be able to access rich dashboard and reports
              with YoY, QoQ and MoM performance, funnel size, sales velocity and
              more. Users will access enterprise wide data of investee or
              subsidiary published in the reporting currency possible through
              Live currency conversion. <br />
              <br />
            </p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <ScrollToTop />
        </div>
      </div>

      <div className="feature-container-3">
        <Signup />
      </div>
    </div>
  )
}

export default Homepage

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Value from "../modules/Value_adds/Value_adds"
const Integrations = () => (
  <Layout>
    <SEO title="Value Adds"
    description="Increase REVENUE, Improve Productivity
    & Grow your CUSTOMER BASE" />
    <Value />
  </Layout>
)

export default Integrations